.ambbet-tab {
    height: 100%;

    .ant-tabs-content {
        height: 100%;
    }
    
    .ant-tabs-nav {
        position: sticky;
        top: 55px;
        background: $gradient-dark;
        border: 1px solid rgba($color-line, 0.2);
        box-shadow: rgba(0, 0, 0, 0.75) 0px 7px 24px 0px;
        margin: 0 -15px;
        z-index: 99;

        &::before {
            display: none;
        }

        @media (min-width: $screen-md) {
            top: 80px;
            margin: 0;
            border-radius: 16px;
            overflow: hidden;

        }
    }

    .ant-tabs-nav-list {
        @media (min-width: $screen-md) {
            width: 100%;
        }
    }

    .ant-tabs-tab {
        font-size: 18px;
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 8px 10px;

        @media (min-width: $screen-md) {
            font-size: 24px;
        }

        &:hover {
            color: $color-primary !important;
        }
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--color-text-primary-bg);
        z-index: 2;
    }

    .ant-tabs-ink-bar {
        background: $gradient-primary !important;
        height: 100% !important;
        z-index: 1;
    }

    .menu-tab {
        .icon img{
            width: 32px;
            margin-bottom: 4px;

            @media (min-width: $screen-md) {
                width: 48px;
            }
        }
    }
}

.ambbet-provider-wrapper {
    padding: 32px 0;
}

.ambbet-provider-card {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
    border-radius: 12px;
    transition: all 250ms ease;
    cursor: pointer;
    will-change: box-shadow, transform;
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient-primary;
        background-size: 150%;
        background-position: 100% 200%;
        border-radius: 12px;
    }

    .ambbet-provider-img {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(325deg, $color-grey-2 30%, lighten($color-grey-2, 7%) 100%);;
        border-radius: 12px;

        img {
            width: 90%;
            margin-bottom: 8px;
            will-change: transform;
            transition: all 250ms ease;
        }
    }

    &:hover {
        // border: 1px solid hsla(var(--color-primary-hsl), 0.5);
        transform: scale(1.015) translateZ(0);
        box-shadow: hsla(var(--color-primary-hsl), 0.2) 0px 7px 24px 0px;

        &::before {
            background-position: 100% 100%;
        }

        .ambbet-provider-img {
            img {
                transform: scale(1.04) translateZ(0);
            }
        }
    }
}

.ambking-provider-card {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
    border-radius: 12px;
    transition: all 250ms ease;
    cursor: pointer;
    will-change: box-shadow, transform;
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient-primary;
        background-size: 150%;
        background-position: 100% 200%;
        border-radius: 12px;
    }

    .ambking-provider-img {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(325deg, $color-grey-2 30%, lighten($color-grey-2, 7%) 100%);;
        border-radius: 12px;

        img {
            width: 100%;
            border-radius: 12px;
            will-change: transform;
            transition: all 250ms ease;
        }
    }

    .provider-name-title {
        font-size: 80%;
        font-style: bold;

        @media (min-width: $screen-xl) {
            font-size: 32px;
            font-style: bold;
        }
    }

    &:hover {
        // border: 1px solid hsla(var(--color-primary-hsl), 0.5);
        transform: scale(1.015) translateZ(0);
        box-shadow: hsla(var(--color-primary-hsl), 0.2) 0px 7px 24px 0px;

        &::before {
            background-position: 100% 100%;
        }

        .ambbet-provider-img {
            img {
                transform: scale(1.04) translateZ(0);
            }
        }
    }
}

.ambbet-provider-has-games {
    display: block;

    &.hide-provider {
        display: none;
    }
}
.ambbet-games {
    display: none;

    &.show-games {
        display: block;
    }

    .ant-typography {
        text-transform: uppercase;
    }
}

.ambmet-game-card {
    position: relative;
    display: block;
    color: $color-white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.25 ease;

    @media (min-width: $screen-md) {
        font-size: 20px;
    }

    &:hover {
        color: $color-primary;
    }
}

.ambmet-game-card-img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
    box-shadow: rgba($color-black, 1) 0 12px 32px -16px;
    transition: all 0.25s ease-in-out;

    &:hover {
        border-color: $color-primary;
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 12px 32px -16px;
        transform: scale(1.025);
    }
}

.ambking-tab {
    .ant-tabs-nav-more {
        display: none;
    }
}