// Background
$background-base: #121416;

// Breakpoint
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// Colors
// $color-primary: #ffc800;
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);;
$color-success: #52c41a;
$color-danger: #e84749;
$color-white: #fff;
$color-black: #000;
$color-grey-1: #a7a7a7;
$color-grey-2: #1b1b1b;
$color-grey-3: #333;

// Gradient
$gradient-primary: linear-gradient(325deg,
    hsl(var(--color-primary-h),
        var(--color-primary-s),
        calc(var(--color-primary-l) + 8%)) 30%,
    hsl(var(--color-primary-h),
        var(--color-primary-s),
        calc(var(--color-primary-l) + 30%)) 100%);
$gradient-secondary: linear-gradient(325deg,
    hsl(var(--color-secondary-h),
        var(--color-secondary-s),
        var(--color-secondary-l)) 30%,
    hsl(var(--color-secondary-h),
        var(--color-secondary-s),
        calc(var(--color-secondary-l) + 40%)) 100%);
$gradient-dark: linear-gradient(325deg, $color-grey-2 30%, lighten($color-grey-2, 5%) 100%);
$gradient-light: linear-gradient(325deg, #EDEDED 0%, $color-white 100%);

// Sidebar
/* hex to filter : https://isotropic.co/tool/hex-color-to-css-filter/ */
$sidebar-icon-color-default: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); // White Color
$sidebar-icon-color-active: invert(83%) sepia(44%) saturate(4415%) hue-rotate(2deg) brightness(107%) contrast(105%); // Yellow Color

// Styles
$color-line: #434343;
$border-radius-card: 20px;

// Typography
$text-color: $color-white;
$text-color-secondary: rgba($color-white, 75%);
