.transaction-tab {
    display: flex;
    background: $gradient-dark;
    border-radius: 60px;

    .ant-radio-button-wrapper {
        flex: auto;
        text-align: center;
        border: 0;

        &:first-child {
            border-radius: 60px 0 0 60px;
        }

        &:last-child {
            border-radius: 0 60px 60px 0;
        }

        &:not(:first-child) {
            &::before {
                display: none;
            }
        }
    }

    .ant-radio-button-wrapper-checked {
        color: var(--color-text-primary-bg) !important;
        background: $gradient-primary !important;
    }
}