.auth-container {
    width: 100%;
	padding: 16px 16px 32px;

    .avatar-logo {
        display: block;
        margin: 0 auto;
    }

    @media (min-width: $screen-md) {
        width: 440px;
    }
}

.captcha-row {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;

	button {
		margin-left: 16px;
	}

	img {
		border: 4px solid $color-primary;
		border-radius: 0.25rem;
	}
}
