.form-card {
    position: relative;
    padding: 24px 16px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;
    z-index: 1;

    @media (min-width: $screen-lg) {
        padding: 24px;
    }

    &::before {
        content: "";
        position: absolute;
        top: -9px;
        right: 0;
        left: 0;
        z-index: 0;
        width: calc(100% - 32px);
        height: 8px;
        margin: 0 auto;
        background: $color-primary;
        border-radius: 4px 4px 0 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: var(--form-card-bg);
    }

    .form-card-body {
        position: relative;
        z-index: 1;
    }

    .ant-statistic {
        .ant-statistic-content {
            font-weight: bold;
            line-height: 0.8;
        }

        .ant-statistic-content-prefix {
            color: $color-primary;
            font-size: 32px !important;
            margin-right: 8px;

            @media (min-width: $screen-lg) {
                font-size: 36px !important;
            }
        }

        .ant-statistic-content-value,
        .ant-statistic-content-value-int,
        .ant-statistic-content-value-decimal {
            font-size: 32px !important;

            @media (min-width: $screen-lg) {
                font-size: 36px !important;
            }
        }
    }
}