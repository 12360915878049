.notice-card {
    display: flex;
    align-items: center;
    padding: 16px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;

    @media (min-width: $screen-md) {
        padding: 20px 24px;
    }

    i {
        font-size: 20px;
    }

    .notice-card-text {
        padding-left: 16px;
        font-size: 22px;

        @media (min-width: $screen-md) {
            font-size: 24px;
        }
    }
}