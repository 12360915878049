.bank-card {
    display: flex;
    padding: 16px;
    background: linear-gradient(315deg, lighten($color-black, 7%) 0%, lighten($color-black, 13%) 100%);
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;

    .bank-card-logo {
        margin-bottom: 0;
        margin-right: 16px;
    }
}

.bank-deposit-card {
    padding: 16px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;

    @media (min-width: $screen-lg) {
        padding: 24px;
    }
}

.bank-card-logo {
    position: relative;
    padding: 12px;
    display: flex;
    border-radius: 16px;
    margin-bottom: 12px;
    width: 80px;
    height: 80px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(315deg, rgba($color-white, 0) 20%, rgba($color-white, 1) 100%);
        border-radius: 16px;
        z-index: 1;
        mix-blend-mode: soft-light;
    }

    img {
        position: relative;
        z-index: 2;
    }
}

.bank-card-info {
    line-height: 1;

    small {
        color: rgba($color-white, 0.7);
    }

    button {
        padding: 0;
        margin-top: 16px;
        font-size: 20px;
        height: auto;
    }

    .bank-deposite-account {
        color: $color-white;
        font-size: 32px;
        font-weight: bold;
    }
}