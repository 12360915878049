body {
    font-weight: normal;
    font-size: 24px;
    font-family: DB HelvethaicaX, Arial, sans-serif;
    padding: 0;
    margin: 0;
    background: #121416;

    &::-webkit-scrollbar {
        width: 0.6em;
        background: var(--color-bg-container);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        background-clip: content-box;
        border: 4px solid transparent;
        border-radius: 100px;
    }
}

html, body, #root {
    height: 100%;
}

.hidden {
    display: none !important;
}

.avatar-logo {
    background: transparent;

    img {
        object-fit: contain;
    }

    .ant-avatar-string {
        color: $color-primary;
        font-size: 40px;
        font-weight: bold;
    }
}

.ambpay-qrcode {
	text-align: center;
	margin-bottom: 16px;

	img {
		width: 150px;
		border: 8px solid #eee;
        border-radius: 8px;
	}
}

.heading {
    color: $color-primary;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}

.primary-color {
    color: $color-white;
}

.test-slider {
    position: relative;
    padding-top: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}