.ranking-card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    background: linear-gradient(315deg, lighten($color-black, 7%) 0%, lighten($color-black, 13%) 100%);
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;
    overflow: hidden;
    line-height: 1;
    color: $color-white;

    &.top-ten {
        .ranking-card-num {
            position: absolute;
            left: -6px;
            bottom: -44px;
            font-size: 136px;
            font-weight: bold;
            transform: skewX(-16deg);
            letter-spacing: -14px;
            width: 90px;
            opacity: 0.2;
            background: linear-gradient(315deg, rgba($color-white, 0.9) 20%, rgba($color-white, 0.0) 90%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .ranking-card-icon {
            padding: 0 12px 0 40px;
        }
    }

    .ranking-card-icon {
        padding-right: 12px;

        img {
            width: 40px;
        }
    }

    .ranking-card-name {
        line-height: 0.85;

        small {
            color: $text-color-secondary;
        }
    }

    .ranking-card-point {
        margin-left: auto;

        img {
            width: 16px;
            margin-top: -2px;
            margin-right: 6px;
        }
    }
}

.ranking-col {
    &:first-child {
        .ranking-card {
            background: $gradient-primary;
            border: 1px solid rgba($color-line, 0.5);
            color: var(--color-text-primary-bg);
            animation: pulseTopOne 2s infinite;

            &.top-ten {
                .ranking-card-num {
                    opacity: 0.6;
                    background: linear-gradient(315deg, rgba($color-black, 1) 60%, rgba($color-black, 0.0) 90%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    &:nth-child(2), &:nth-child(3) {
        .ranking-card {
            background: linear-gradient(315deg, hsla(var(--color-primary-hsl), 7%) 0%, lighten($color-black, 8%) 100%);
            border: 1px solid hsla(var(--color-primary-hsl), 0.4);

            &.top-ten {
                .ranking-card-num {
                    opacity: 0.6;
                    background: linear-gradient(315deg, hsla(var(--color-primary-hsl), 1) 60%, hsla(var(--color-primary-hsl), 0.0) 90%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}