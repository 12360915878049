@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 $color-secondary;
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 $color-secondary;
        box-shadow: 0 0 0 0 $color-secondary;
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
        box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
        box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
    }
}

@-webkit-keyframes pulseTopOne {
    0% {
        -webkit-box-shadow: 0 0 0 0 $color-primary;
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
    }
}

@keyframes pulseTopOne {
    0% {
        -moz-box-shadow: 0 0 0 0 $color-primary;
        box-shadow: 0 0 0 0 $color-primary;
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
        box-shadow: 0 0 0 10px rgba(135, 38, 255, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
        box-shadow: 0 0 0 0 rgba(135, 38, 255, 0);
    }
}

@-webkit-keyframes pulseWheel {
    0% {
        -webkit-box-shadow: 0 0 20px 0 $color-primary;
    }

    70% {
        -webkit-box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
    }
}

@keyframes pulseWheel {
    0% {
        -moz-box-shadow: 0 0 20px 0 $color-primary;
        box-shadow: 0 0 20px 0 $color-primary;
    }

    70% {
        -moz-box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
        box-shadow: 0 0 20px 40px rgba(255, 200, 0, 0);
    }

    100% {
        -moz-box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
        box-shadow: 0 0 20px 0 rgba(255, 200, 0, 0);
    }
}

@-webkit-keyframes pulseWheelMobile {
    0% {
        -webkit-box-shadow: 0 0 30px 0 $color-primary;
    }

    70% {
        -webkit-box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
    }
}

@keyframes pulseWheelMobile {
    0% {
        -moz-box-shadow: 0 0 30px 0 $color-primary;
        box-shadow: 0 0 30px 0 $color-primary;
    }

    70% {
        -moz-box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
        box-shadow: 0 0 30px 10px rgba(255, 200, 0, 0);
    }

    100% {
        -moz-box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
        box-shadow: 0 0 30px 0 rgba(255, 200, 0, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes blinkTo {
    50% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
}

@keyframes blinkForm {
    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(-45deg);
    }

    1% {
        transform: translate(-1px, -2px) rotate(-55deg);
    }

    2% {
        transform: translate(-3px, 0px) rotate(-35deg);
    }

    3% {
        transform: translate(3px, 2px) rotate(-45deg);
    }

    4% {
        transform: translate(1px, -1px) rotate(-35deg);
    }

    5% {
        transform: translate(-1px, 2px) rotate(-55deg);
    }

    6% {
        transform: translate(-3px, 1px) rotate(-45deg);
    }

    7% {
        transform: translate(3px, 1px) rotate(-55deg);
    }

    8% {
        transform: translate(-1px, -1px) rotate(-35deg);
    }

    9% {
        transform: translate(1px, 2px) rotate(-45deg);
    }

    10% {
        transform: translate(1px, -2px) rotate(-55deg);
    }

    11% {
        transform: translate(1px, 1px) rotate(-45deg);
    }
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}