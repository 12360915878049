.menu-sidebar {
    margin: 0;
    padding: 0 32px 16px;
    overflow-x: hidden;
    overflow-y: auto;

    li {
        list-style: none;

        a {
            position: relative;
            display: flex;
            padding: 12px 0;
            color: $color-grey-1;
            font-size: 24px;

            i {
                width: 24px;
                margin-right: 20px;
                color: $color-grey-1;
                font-size: 0.9rem;
                text-align: center;
            }

            img {
                width: 24px;
                margin-right: 20px;
                opacity: 0.5;
                filter: $sidebar-icon-color-default;
            }

            svg {
                width: 24px;
                height: 29px;
                margin-right: 20px;
                opacity: 0.5;

                path {
                    fill: $color-white;
                }
            }

            &:hover {
                &:not(.active-menu) {
                    color: $color-white;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: -48px;
                        bottom: 0;
                        left: -48px;
                        z-index: 0;
                        background-color: rgba($color-white, 0.03);
                    }
                }
            }
        }

        a.active-menu {
            color: $color-primary;

            i {
                color: $color-primary;
            }

            img {
                opacity: 1;
                filter: $sidebar-icon-color-active;
            }

            svg {
                opacity: 1;

                path {
                    fill: $color-primary
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: -48px;
                bottom: 0;
                left: -48px;
                z-index: -1;
            }
        }
    }
}

.bottombar-mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    background: $gradient-dark;
    border-top: 1px solid rgba($color-line, 0.2);

    @media screen and (min-width: $screen-lg) {
        display: none;
    }

    .nav-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-around;
        margin: 0;
        padding: 0;

        .nav-item {
            -ms-flex: 1;
            flex: 1;
            text-align: center;
            list-style-type: none;
            -webkit-box-flex: 1;

            .nav-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                height: 100%;
                padding: 6px 0;
                color: $color-white;
                text-decoration: none;
                opacity: 0.75;

                i {
                    display: block;
                    color: $color-primary;
                    line-height: 1.4;
                }

                img {
                    margin-bottom: 6px;

                    &:not(.icon-center) {
                        width: 24px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                }

                svg {
                    width: 24px;
                    height: 24px;
                    margin-bottom: 6px;

                    path {
                        fill: $color-white;
                    }
                }

                span {
                    display: block;
                    font-size: 18px;
                    line-height: 1;
                }
            }

            .nav-link.active {
                color: $color-primary;
                opacity: 1;

                img {
                    &:not(.icon-center) {
                        filter: invert(83%) sepia(44%) saturate(4415%) hue-rotate(2deg) brightness(107%) contrast(105%);
                    }
                }

                svg {
                    path {
                        fill: $color-primary
                    }
                }
            }
        }

        .nav-item.middle-item {
            span {
                display: block;
                margin-top: 5px;
                color: rgba($color-white, 0.75);
                font-size: 18px;
            }

            .nav-link {
                opacity: 1;
            }

            .icon-button {
                position: absolute;
                bottom: 50%;
                z-index: 1;
                width: 60px;
                height: 60px;
                margin: -1rem auto auto;
                padding: 0;
                color: $color-grey-1;
                border-radius: $border-radius-card;
                overflow: hidden;
                transform: rotate(45deg);
                transition: -webkit-transform 0.15s ease-out;
                transition: transform 0.15s ease-out;
                animation: pulse 2s infinite;

                i {
                    position: relative;
                    display: flex;
                    align-content: center;
                    color: $color-white;
                    font-size: 28px;
                    line-height: 1;
                }
            }

            .icon-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: $border-radius-card;

                img {
                    position: relative;
                    z-index: 1;
                    width: 32px;
                    transform: rotate(-45deg);
                    animation: shake;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -2;
                    width: 120%;
                    height: 120%;
                    background-color: transparent;
                    background-image: linear-gradient(90deg,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 0%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 1) 50%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 100%);
                    background-repeat: no-repeat;
                    transform-origin: center;
                    -webkit-animation: rotate 2s linear infinite;
                    animation: rotate 2s linear infinite;
                    content: '';
                }

                &::after {
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    background: $gradient-secondary;
                    border-radius: $border-radius-card;
                    content: '';
                }
            }
        }
    }
}

.menu-mobile-wrapper {
    @media screen and (min-width: $screen-md) {
        margin-bottom: 32px;
    }

    @media screen and (min-width: $screen-xl) {
        display: none;
    }
}

.menu-mobile {
    display: block;
    color: $color-white;
    font-size: 20px;
    text-align: center;
}

.menu-mobile-icon {
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    padding-top: 100%;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        transform: translate(-50%, -50%);
    }
}

.account-menu {
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;
    display: flex;
    align-items: center;
    padding: 16px;
    transition: all 0.25s ease;

    .anticon {
        margin-left: auto;
        color: $color-white;
        font-size: 20px;
        transition: all 0.25s ease;
    }

    .topbar-name {
        transition: all 0.25s ease;
    }

    .account-menu-name {
        display: flex;
        align-items: center;
        color: $color-white;
        transition: all 0.25s ease;

        svg {
            margin-right: 16px
        }
    }

    svg {
        width: 24px;
        height: 24px;
        fill: $color-white;
    }

    &:hover {
        border-color: hsla(var(--color-primary-hsl), 0.1);

        .anticon,
        .topbar-name,
        .account-menu-name {
            color: $color-primary;
        }
    }
}

.game-menu {
    position: sticky;
    top: 80px;
    padding: 16px 8px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
}

.game-menu-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    border-radius: 12px;
    cursor: pointer;
    transition: color .25s ease;
    will-change: color;

    @media (min-width: $screen-md) {
        padding: 12px 4px;
        font-size: 22px;
    }

    img {
        width: 28px;
        margin-bottom: 4px;
        filter: grayscale(50%);
        transition: all .25s ease;
        will-change: transform, filter;

        @media (min-width: $screen-md) {
            width: 48px;
        }
    }

    &.active {
        color: $color-black;
        font-weight: 700;
        background: $gradient-primary;
        box-shadow: hsla(var(--color-primary-hsl), 20%) 0 10px 20px -20px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        &:not(.active) {
            color: $color-primary;

            img {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
        }
    }
}