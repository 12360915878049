.deposit-method {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    color: var(--color-text-primary-bg);
    background: $gradient-primary;
    border: 1px solid rgba($color-white, 0.2);
    border-radius: $border-radius-card;
    transition: all 0.25s ease;
    will-change: transform, box-shadow;
    cursor: pointer;

    &.bg-secondary {
        background: $gradient-dark;
        border: 1px solid rgba($color-white, 0.05);

        .deposit-method-name {
            color: $color-white;

            small {
                color: rgba($color-white, 0.5);
            }
        }

        .anticon-right {
            color: $color-white;
        }

        &:hover {
            box-shadow: rgba($color-black, 0.5) 0 25px 20px -20px;
        }
    }

    .deposit-method-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        background: $gradient-light;
        border-radius: 50%;
        transition: all 0.25s ease;
        will-change: transform, box-shadow;

        @media (min-width: $screen-lg) {
            width: 56px;
            height: 56px;
        }

        img {
            max-width: 24px;
            max-height: 24px;

            @media (min-width: $screen-lg) {
                max-width: 32px;
                max-height: 32px;
            }
        }
    }

    .deposit-method-name {
        font-size: 28px;
        line-height: 0.8;

        @media (min-width: $screen-lg) {
            font-size: 32px;
        }

        small {
            color: var(--color-text-primary-bg);
            opacity: 0.75;

            @media (min-width: $screen-lg) {
                font-size: 22px;
            }
        }
    }

    .anticon-right {
        margin-left: auto;
    }

    &:hover {
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 25px 20px -20px;
        transform: scale(1.015);

        .deposit-method-icon {
            box-shadow: rgba($color-black, 0.2) 0 8px 6px -6px;
            transform: scale(1.05);

            @media (min-width: $screen-lg) {
                box-shadow: rgba($color-white, 0.2) 0 16px 12px -12px;
            }
        }
    }
}

.truewallet-qr-deposit {
	margin-top: 16px;
	text-align: center;

	img {
		width: 150px;
		border: 1px solid rgba($color-white, 0.2);
		border-radius: 0.25rem;
	}
	h5 {
		margin: 0.5rem 0 0 0;
		font-size: 1.2rem;
		color: $color-primary;
	}
}