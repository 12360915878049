.wallet-container {
    margin-bottom: 16px;
    padding: 24px;
    color: var(--color-text-primary-bg);
    line-height: 1;
    background: $gradient-primary;
    border: 1px solid hsl(
        var(--color-primary-h),
        var(--color-primary-s),
        calc(var(--color-primary-l) + 30%));
    border-radius: $border-radius-card;

    @media (min-width: $screen-lg) {
        margin-bottom: 32px;
        padding: 32px;
    }
}

.wallet-amount {
    font-weight: bold;
    font-size: 64px;

    @media (min-width: $screen-lg) {
        font-size: 86px;
    }
}

.turn-badge {
    display: inline-block;
    padding: 6px 12px;
    background: $gradient-light;
    border: 1px solid rgba($color-white, 0.25);
    border-radius: 32px;

    .ant-typography {
        color: $color-black;
        font-size: 20px;

        @media (min-width: $screen-lg) {
            font-size: 24px;
        }
    }
}

.wallet-cashback {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    line-height: 1;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;

    @media (min-width: $screen-lg) {
        padding: 20px 32px;
    }

    .wallet-cashback-amount {
        color: $color-white;
        font-weight: bold;
        font-size: 40px;

        @media (min-width: $screen-lg) {
            font-size: 48px;
        }
    }
}
