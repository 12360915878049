.ant-modal-mask {
    backdrop-filter: blur(4px);
}

.ant-modal-header {
    background: transparent !important;
    border-bottom: 1px solid rgba($color-line, 0.5);
    padding: 8px 16px;

    .ant-modal-title {
        font-size: 32px;
    }

    @media (min-width: $screen-md) {
        padding: 12px 24px;
    }
}

.ant-modal-body {
    padding: 8px 16px;

    @media (min-width: $screen-md) {
        padding: 12px 24px;
    }
}

.ant-modal-content {
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: $border-radius-card;
    padding: 0 !important;

    .ant-modal-close {
        width: 32px;
        height: 32px;
        border-radius: 50%;

        .anticon-close {
            font-size: 20px;
            transition: color 0.25s ease;
        }

        &:hover {
            .anticon-close {
                color: $color-primary;
            }
        }
    }
}

.ant-modal-deposit {
    @media (min-width: $screen-md) {
        width: 440px;
        max-width: 440px;
    }

    .ant-modal-footer {
        padding: 0 16px 24px;
        border-top: 0;

        @media (min-width: $screen-md) {
            padding: 0 24px 24px;
        }
    }

    .ant-modal-deposit-label {
        display: flex;
        margin-bottom: 6px;
    }

    .ant-modal-deposit-link {
        margin-left: auto;
        color: $color-primary;
        cursor: pointer;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.75;
        }
    }
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    &.no-margin {
        margin-left: 0 !important;
    }
}