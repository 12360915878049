.web-container {
    padding: 24px 16px 100px;
    margin: 0 auto;
    width: 100%;

    @media (min-width: $screen-sm) {
        max-width: 540px;
    }

    @media (min-width: $screen-md) {
        max-width: 720px;
    }

    @media (min-width: $screen-lg) {
        padding: 32px 32px 100px;
        max-width: 960px;
    }

    @media (min-width: $screen-xl) {
        padding: 32px 32px;
        max-width: 1140px;
    }

    @media (min-width: $screen-xxl) {
        padding: 48px 16px;
        max-width: 1320px;
    }
}

.redo-button {
    will-change: transform;
    transition: all 0.25s ease;

    &:hover {
        transform: rotate(360deg);
    }
}