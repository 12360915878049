.promotion-wallet-container {
    display: none;

    @media (min-width: $screen-md) {
        display: block;
    }
}

.promotion-card {
    border-radius: $border-radius-card;

    .promotion-actions {
        margin-top: 24px;

        @media (min-width: $screen-xl) {
            button {
                min-width: 140px;
            }
        }
    }

    .ant-card-cover {
        margin: 0;

        img {
            border-radius: 20px 20px 0 0;
        }
    }

    .ant-card-body {
        border-top: 1px solid #303030;
    }

    .ant-card-meta-title {
        color: $color-primary;
        white-space: normal;
    }
}

.promotion-actions {
    display: flex;

    .ant-radio-wrapper {
        flex: auto;
        margin-right: 16px;
    }
}