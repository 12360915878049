.invite-card {
    position: relative;
    display: none;
    width: 100%;
    padding: 32px 24px;
    background: $gradient-dark;
    border: 1px solid #2a2a2a;
    border-bottom: 0;
    border-radius: 20px 20px 0 0;

    @media (min-width: $screen-xl) {
        display: block;
    }

    &::before {
        position: absolute;
        top: 3px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -8 116 L100 20 L210 116" fill="%23121416" stroke="%232a2a2a" stroke-width="1" vector-effect="non-scaling-stroke"/></svg>');
        background-repeat: repeat-x;
        background-position: bottom left;
        background-size: 5% auto;
        content: '';
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: var(--form-card-bg);
    }

    .invite-card-body {
        position: relative;
        z-index: 1;
    }
}

.invite-qrcode-container {
    position: relative;
    display: table;
    margin: 0 auto;
    margin-bottom: 32px;

    .large-coin,
    .medium-coin,
    .small-coin,
    .x-large-coin {
        position: absolute;
    }

    .small-coin {
        right: -20px;
        bottom: 33px;
        z-index: 1;

        svg {
            width: 26px;
            height: 26px;
            transform: rotate(15deg);
        }
    }

    .medium-coin {
        bottom: 38px;
        left: -22px;
        z-index: 1;

        svg {
            width: 32px;
            height: 32px;
            transform: rotate(-20deg);
        }
    }

    .large-coin {
        right: -36px;
        bottom: 0;
        z-index: 4;

        svg {
            width: 40px;
            height: 40px;
            transform: rotate(30deg);
        }
    }

    .x-large-coin {
        bottom: 0;
        left: -44px;
        z-index: 4;

        svg {
            width: 48px;
            height: 48px;
            transform: rotate(-35deg);
        }
    }

    .invite-qrcode {
        position: relative;
        z-index: 3;
        padding: 8px 8px 0;
        background: var(--color-bg-container);
        border-radius: 16px;
    }
}