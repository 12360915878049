.transaction-card {
	background: $gradient-dark;
	border-radius: $border-radius-card;
    margin-bottom: 16px;

	.transaction-header, .transaction-body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 1;
	}

	.transaction-header {
		border-bottom: 1px solid rgba($color-line, 0.3);
        padding: 12px 16px;
    }

    .transaction-body {
        padding: 12px 16px 16px;
    }

	.transaction-footer {
		padding: 0 16px 20px;
	}

	.transaction-remark {
		background: #2a1215;
		border: 1px solid #58181C;
		border-radius: 12px;
		font-size: 22px;
		color: $color-danger;
		padding: 6px 16px;
		margin-bottom: 12px;
	}

    .transaction-title {
		color: $color-primary;
        font-size: 36px;
		font-weight: bold;
		line-height: 1;
	}

	.ant-btn {
        line-height: 0.8;
        width: 100%;

        img {
            width: 16px;
            margin-right: 8px;
        }
	}

    .ant-tag {
        font-size: 20px;
        margin-right: 0;
        padding: 6px 8px;
    }

	@media (min-width: $screen-md) {
		.transaction-header {
			padding: 16px 24px;
		}

		.transaction-body {
			padding: 16px 24px 24px;
		}

		.transaction-footer {
			display: flex;
			align-items: flex-start;
			width: 100%;
			padding: 0 24px 24px;
		}

		.transaction-remark {
			flex: auto;
			font-size: 22px;
			margin-bottom: 0;
			margin-right: 16px;
            padding: 12px 16px;
		}

        .ant-btn {
			min-width: 180px;
            width: auto;
		}

        .ant-tag {
            font-size: 22px;
        }
	}
}