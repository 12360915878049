.layout-header {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    height: auto;
    min-height: 56px;
    padding: 8px 16px;
    line-height: 1;
    background-color: rgba($background-base, .85);
    border-bottom: 1px solid rgba($color-line, 0.2);
    backdrop-filter: blur(6px);

    @media (min-width: $screen-lg) {
        min-height: auto;
        padding: 8px 32px;
    }
}

.topbar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.topbar-hidden {
        display: none;

        @media (min-width: $screen-lg) {
            display: flex;
        }
    }
}

.topbar-logo {
    @media (min-width: $screen-lg) {
        display: none;
    }

    .ant-avatar {
        width: auto;
        max-width: 125px;
        height: 48px;
        max-height: 48px;
    }

    .prefix-name {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
    }
}

.topbar-welcome {
    display: none;

    @media (min-width: $screen-lg) {
        display: flex;
        align-items: center;
    }
}

.topbar-member {
    display: flex;

    .topbar-profile {
        display: flex;
        align-items: center;
    }

    .topbar-profile-rank {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 40px;
        }
    }

    .topbar-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 36px;
        height: 36px;
        color: $color-black;
        font-weight: 700;
        background: $gradient-primary;
        border-radius: 50%;
    }

    .topbar-info {
        padding: 0 0 0 12px;
        font-size: 20px;

        .anticon {
            color: $color-primary;
            font-size: 16px;
            opacity: 0.85;
            will-change: opacity;
            transition: opacity 0.25s ease;

            &:hover {
                opacity: 1;
            }
        }

        @media (min-width: $screen-lg) {
            padding: 0 12px;
            font-size: 24px;
        }
    }

    button {
        display: none;

        @media (min-width: $screen-lg) {
            display: block;
        }
    }
}

.topbar-mobile {
    position: relative;
    display: flex;
    width: 100%;

    @media (min-width: $screen-lg) {
        display: none;
    }

    .topbar-mobile-title {
        margin: 0 auto;
    }

    .anticon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}