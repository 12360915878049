.ant-btn-primary {
    background: $gradient-primary !important;
    box-shadow: 0 0 0 0 hsla(var(--color-primary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-primary-bg) !important;
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 25px 20px -20px;
    }
}

.ant-btn-purple {
    background: $gradient-secondary !important;
    box-shadow: 0 0 0 0 hsla(var(--color-secondary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-secondary-bg) !important;
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-secondary-hsl), 0.5) 0 25px 20px -20px;
    }
}

.ant-btn-line {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin-top: 2px;
    }

    &:hover {
        color: #06C755;
        border-color: #06C755;
    }
}