.slot-card {
    position: relative;
    display: block;
    color: $color-white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.25 ease;

    @media (min-width: $screen-md) {
        font-size: 20px;
    }

    &:hover {
        color: $color-primary;
    }
}

.slot-card-img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
    box-shadow: rgba($color-black, 1) 0 12px 32px -16px;
    transition: all 0.25s ease-in-out;

    &:hover {
        border-color: $color-primary;
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 12px 32px -16px;
        transform: scale(1.025);
    }
}