.lucky-wheel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 180px);

    @media (min-width: $screen-lg) {
        height: 72vh;
    }
}

.lucky-wheel {
    position: relative;
    width: 100%;
    max-width: 516px;
    max-height: 516px;
    aspect-ratio: 1 / 1;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -52%);
        width: 100%;
        aspect-ratio: 1 / 1;
        background: transparent;
        border-radius: 50%;
        box-shadow: hsla(var(--color-primary-hsl), 0.25) 0px 25px 50px -12px;
    }
}

.lucky-wheel-frame {
    img {
        width: 100%;
        position: absolute;
        top: calc(50% - 15px);
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .animate-form {
        animation: blinkForm 0.5s step-start 0s infinite;
    }

    .animate-to {
        animation: blinkTo 0.5s step-start 0s infinite;
    }
}

.lucky-wheel-wheel {
    height: 100%;

    canvas {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 84%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

.lucky-wheel-arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    img {
        width: 36px;

        @media (min-width: $screen-sm) {
            width: 48px;
        }
    }
}

.lucky-wheel-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    &:before {
        content: '';
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        z-index: -1;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        animation: pulseWheel 1s infinite;
    }

    .lucky-wheel-logo-bg {
        width: 80%;
    }

    .logo-wheel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
    }
}

.lucky-wheel-play {
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    z-index: 4;

    img {
        width: 180px;
    }

    .lucky-wheel-play-enable {
        cursor: pointer;
        transition: all 0.25s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    .lucky-wheel-play-disable {
        cursor: not-allowed;
    }
}